#{$theme} {
  font-family: $ims-font-news;
  font-size: $font-size-regular;
  color: $ims-brown-color;
  margin-bottom: 16px;
}

#{$theme} header {
  padding-top: 20px;
  background-color: $ims-white-color;
}

#{$theme} .navbar {
  padding: 0;
}

#{$theme} .navbar-brand {
  padding: 0;
}

#{$theme} header .nav-link:hover,
#{$theme} header .btn-link:hover {
  opacity: 0.8;
}

#{$theme} .nav .nav-link,
#{$theme} .nav .btn-link {
  font-size: 16px;
  margin-top: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 0px;
  border-top: 1px solid $ims-light-grey-color;
  border-bottom: 1px solid $ims-light-grey-color;
  margin-bottom: 50px;
}

#{$theme} .nav .nav-link:hover,
#{$theme} .nav .btn-link:hover {
  color: $ims-dark-red-color;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
