#{$theme} {
  font-family: $ims-font-news;
  color: $ims-red-color;
  padding-bottom: 16px;
}

#{$theme} header {
  background-color: $knistr-CYAN;
}

#{$theme} .navbar {
  align-items: unset;
}

#{$theme} .navbar-brand {
  padding: unset;
  margin: unset;
}

#{$theme} header .nav-link:hover,
#{$theme} header .btn-link:hover {
  opacity: 0.8;
}

#{$theme} .nav {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
}

#{$theme} .nav .nav-link:hover,
#{$theme} .nav .btn-link:hover {
  color: $ims-black-color;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: $ims-light-grey-color;
  border-radius: 0.3rem;
}
