#{$theme} {
  font-family: $ims-font-news;
  font-size: $font-size-regular;
  color: $ims-black-color;
  margin-bottom: 16px;
}

#{$theme} header {
  background-color: $ims-yellow-color;
  height: 41px;
}

#{$theme} .navbar {
  padding: 0px 12px 0px 12px;
}

#{$theme} .navbar-brand {
  padding: 0;
}

.nav-item {
  padding-left: 8px;
}

#{$theme} header .nav-link,
#{$theme} header .nav-link:hover,
#{$theme} header .btn-link,
#{$theme} header .btn-link:hover {
  color: $ims-black-color;
  font-weight: bold;
  font-size: $font-size-regular;
  cursor: pointer;
  padding-right: 32px;
}

#{$theme} header .nav-link:hover,
#{$theme} header .btn-link:hover {
}

#{$theme} .nav {
}

#{$theme} .nav .nav-link:hover,
#{$theme} .nav .btn-link:hover {
  color: white;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
