$checkout-border-radius: 0;

#{$theme} .check {
  position: relative;
  padding: 4px 0 0 30px;
  cursor: pointer;
  color: $ims-black-color;
  font-weight: 400;
  font-family: $ims-font-regular;
  font-size: $font-size-regular;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:focus ~ .check__indicator {
      background: $ims-forms-background;
      border: 1px solid $ims-black-color;
    }

    &:checked ~ .check__indicator {
      background: $ims-grey-color;
      border: 1px solid $ims-grey-color;
    }

    &:checked:focus ~ .check__indicator {
      background: $ims-grey-color;
      border: 1px solid $ims-grey-color;
    }

    &:disabled ~ .check__indicator {
      pointer-events: none;
      opacity: 0.6;
      background: $ims-grey-color;
    }

    &:checked ~ .check__indicator::after {
      display: block;
    }
  }

  &__indicator {
    position: absolute;
    top: 6px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: $checkout-border-radius;
    background: $ims-forms-background;
    border: 1px solid $ims-black-color;

    &::after {
      position: absolute;
      display: none;
      content: "";
    }
  }

  &--radio {
    .check__indicator {
      border-radius: $checkout-border-radius;
      background: $ims-forms-background;
    }

    .check__indicator::after {
      top: 1px;
      left: 1px;
      width: 18px;
      height: 18px;
      border-radius: $checkout-border-radius;
      background: $ims-grey-color;
    }

    input:disabled ~ .check__indicator::after {
      background: $ims-forms-background;
    }

    input:checked ~ .check__indicator::after {
      border: 3px solid $ims-white-color;
    }
  }

  &--checkbox {
    .check__indicator::after {
      top: 3px;
      left: 7px;
      width: 6px;
      height: 11px;
      transform: rotate(45deg);
      border: solid $ims-white-color;
      border-width: 0 2px 2px 0;
    }

    input:disabled ~ .check__indicator::after {
      border-color: $ims-grey-color;
    }
  }
}
