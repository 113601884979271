//colors
$c-active-primary: #428bca;
$c-active-secondary: darken($c-active-primary, 10%);
$c-link-text: #337ab7;
$c-link-hover: #23527c;
$c-link-focus: $c-link-hover;

$ims-white-color: #fff;
$ims-black-color: #191919;
$ims-dark-black-color: #000;
$ims-red-color: #1f2b44;
$ims-dark-red-color: #ab2233;
$ims-grey-color: #9e9e9e;
$ims-light-grey-color: #ebe7de;
$ims-dark-grey-color: #373542;
$ims-turquoise-color: #01bace;
$ims-blue-color: #1d398d;
$ims-green-color: #4d943a;
$ims-red-inactive-color: #f58c86;
$ims-light-grey-inactive-color: #a6a6ab;
$ims-turquoise-highlight-color: #ccf1f5;

$ims-border-gray: #ddd;
$ims-forms-background: #f1f1f4;
$ims-forms-border-focus: #908f9f;
$ims-forms-placeholder: #888;

$font-size-small: 12px;
$font-size-table: 13px;
$font-size-label: 14px;
$font-size-regular: 14px;
$font-size-pagination: 16px;
$font-size-large: 18px;
$font-size-x-large: 24px;
