#{$theme} {
  font-family: $ims-font-regular;
  font-size: $font-size-regular;
  color: $ims-grey-color;
}

#{$theme} .nav-link {
  color: $ims-black-color;
  text-decoration: none;
  padding-bottom: 4px;
}

#{$theme} .nav-link:hover {
  color: $ims-black-color;
  border-bottom: 1px solid $ims-black-color;
  padding-bottom: 3px;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
