#{$theme} {
  font-family: $ims-font-news;
  color: $ims-grey-color;
  margin-bottom: 16px;
}

#{$theme} header {
  background-image: linear-gradient(to bottom, #c31525 105px, #8e2034 140px);
}

#{$theme} .navbar {
  padding: 0px 12px 0px 12px;
}

#{$theme} .navbar-brand {
  padding: 28px 0px 24px 0px;
}

.nav-item {
  padding-left: 8px;
}

#{$theme} header .nav-link,
#{$theme} header .nav-link:hover,
#{$theme} header .btn-link,
#{$theme} header .btn-link:hover {
  color: white;
  cursor: pointer;
}

#{$theme} header .nav-link:hover,
#{$theme} header .btn-link:hover {
  opacity: 0.8;
}

#{$theme} .nav {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
}

#{$theme} .nav .nav-link:hover,
#{$theme} .nav .btn-link:hover {
  color: $ims-dark-red-color;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
