$font-folder-path: "../fonts/";

@mixin font-face($fontfamily, $filename, $fontweight: 400) {
  $temp-font-path: $font-folder-path + $filename;

  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: normal;
    font-stretch: normal;
    src: url($temp-font-path + ".eot");
    src: url($temp-font-path + ".eot?#iefix") format("embedded-opentype"),
      url($temp-font-path + ".woff") format("woff"),
      url($temp-font-path + ".ttf") format("truetype");
  }
}

@include font-face($ims-font-bold, "ClanWebPro-Bold");
@include font-face($ims-font-medium, "ClanWebPro-Medium");
@include font-face($ims-font-news, "ClanWebPro-News");
