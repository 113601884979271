#{$theme} .form-control {
  border: 1px solid $ims-border-gray;
  background-color: $ims-white-color;
  border-radius: 5px;
  box-shadow: inset 6.123233995736765e-17px 1px 1px 0 rgba(0, 0, 0, 0.1);
  font-size: $font-size-regular;
  color: $ims-grey-color;
  padding-top: 8px;
  font-weight: normal;

  &[disabled],
  &[readonly] {
    background-color: $ims-forms-background;
    opacity: 0.65;
  }

  &:focus {
    border-color: $ims-forms-border-focus;
    box-shadow: inset 1.8369701987210304e-16px 3px 1px 0 rgba(0, 0, 0, 0.1);
  }

  &::placeholder {
    color: $ims-grey-color;
    opacity: 0.65;
    font-weight: normal !important;
  }
}

#{$theme} .has-error * {
  color: $ims-red-color !important;
  border-color: $ims-red-color !important;
}

#{$theme} .help-block {
  font-size: $font-size-small;
  display: block;
}

#{$theme} label {
  font-family: $ims-font-medium;
  color: $ims-grey-color;
  font-size: $font-size-label;
  font-weight: normal;
  margin-bottom: 2px;
}

#{$theme} .form-group.select {
  position: relative;

  &::before {
    border-top: 5px dashed;
    border-top: 5px solid\9;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: "";
    position: absolute;
    right: 14px;
    bottom: 16px;
    color: $ims-grey-color;
  }

  &::after {
    content: "";
    width: 1px;
    height: 38px;
    position: absolute;
    bottom: 0;
    right: 36px;
    background-color: $ims-border-gray;
  }

  select {
    appearance: none;
    border: 1px solid $ims-border-gray;
    cursor: pointer;
    padding-right: 40px;
    line-height: 1;

    option {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus,
    &:visited {
      background-color: $ims-white-color;
      border-color: $ims-border-gray;
      outline: none !important;
      box-shadow: inset 6.123233995736765e-17px 1px 1px 0 rgba(0, 0, 0, 0.1) !important;
    }
  }
}

#{$theme} textarea {
  min-height: 60px;
}

#{$theme} input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

#{$theme} input[type="number"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
