#{$theme} {
  font-family: $ims-font-news;
  color: $ims-black-color;
  margin-bottom: 16px;
}

#{$theme} header {
  background-color: $ims-white-color;
}

#{$theme} .navbar {
  align-items: unset;
  padding: 24px 14px 24px 14px;
}

#{$theme} .nav {
  margin-bottom: 24px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 18px;
  background-color: $ims-light-grey-color;
  padding-left: 16px;
  padding-right: 16px;
}

#{$theme} .nav .nav-link,
#{$theme} .nav .btn-link,
#{$theme} .navbar-nav .nav-link,
#{$theme} .navbar-nav .btn-link {
  padding-left: 0px;
  padding-right: 0px;
  color: $ims-black-color;
}

#{$theme} .nav .nav-link::after {
  content: " • ";
  color: #aa9d90;
  vertical-align: top;
  font-size: 0.75rem;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 8px;
  padding-right: 12px;
  line-height: 26px;
  border-bottom: none;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
