#{$theme} {
  font-family: $ims-font-news;
  color: $ims-red-color;
  margin-bottom: 16px;

  background-color: #f3f3f3;
}

#{$theme} header {
  background-color: $ims-white-color;
  border-top: 10px solid $ims-red-color;
  padding-top: 30px;
}

#{$theme} .navbar {
  align-items: unset;
  padding: 0px 4px 0px 4px;
}

#{$theme} header .nav-link:hover,
#{$theme} header .btn-link:hover {
  opacity: 0.8;
}

#{$theme} .nav {
  margin-bottom: 15px;
  background-color: $ims-white-color;
}

#{$theme} .nav .nav-link,
#{$theme} .nav .btn-link {
  color: $ims-dark-grey-color;
  font-weight: 400;
  font-size: 18px;
  padding-left: 0;
  padding-right: 32px;
}

#{$theme} .nav .nav-link:hover,
#{$theme} .nav .btn-link:hover {
  color: $ims-dark-green-color;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
