$button-border-radius: 5px;

$button-font-size: 14px;
$button-line-height: 1;

$button-font-family: $ims-font-news;
$button-font-family-small: $ims-font-news;
$button-padding: 10px 20px;

// mixins

@mixin button-color($name, $color, $bg) {
  #{$theme} .btn-#{$name} {
    color: $color;
    background-color: $bg;
  }
}

@mixin button-color-hover($name, $color, $bg) {
  #{$theme} .btn-#{$name}:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: $color;
      background-color: $bg;
    }
  }
}

// button
#{$theme} .btn {
  font: $button-font-size $button-font-family;
  color: $ims-white-color;
  padding: $button-padding;
  text-decoration: none;
  border: 1px solid $ims-dark-black-color;
  border-radius: $button-border-radius;
  cursor: pointer;
  user-select: none;
  text-align: center;

  line-height: $button-line-height;

  &:not([disabled]) &:hover,
  &:not([disabled]) &:focus,
  &:not([disabled]) &:active {
    color: $ims-red-color;
  }

  &[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.65;
  }

  &-primary {
    padding-right: 20px;
  }

  &--icon {
    padding: 3px 7px 1px;
    width: 32px;

    span::before {
      font-size: $font-size-regular;
    }
  }
}

// link button
#{$theme} .btn-link {
  color: $ims-red-color;
  border: 0;
  text-align: left;
  padding: 8px;
  font: $button-font-size $button-font-family-small;
  line-height: 1.5;
  &:hover {
    text-decoration: none;
    color: $ims-red-color;
  }
}

// button: colors

@include button-color("primary", $ims-white-color, $ims-dark-black-color);
@include button-color("secondary", $ims-white-color, $ims-dark-black-color);
@include button-color("tertiary", $ims-white-color, $ims-dark-black-color);

// button: colors hover

@include button-color-hover("primary", $ims-white-color, $ims-dark-gray-color);
@include button-color-hover(
  "secondary",
  $ims-white-color,
  $ims-dark-gray-color
);
@include button-color-hover("tertiary", $ims-white-color, $ims-dark-gray-color);
