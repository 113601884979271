$font-folder-path: "../fonts/";

@mixin font-face($fontfamily, $filename, $fontweight: 400) {
  $temp-font-path: $font-folder-path + $filename;

  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: normal;
    font-stretch: normal;
    src: url($temp-font-path);
  }
}

//fonts
$ims-font-bold: "Roboto-Bold";
$ims-font-medium: "Roboto-Medium";
$ims-font-news: "Roboto-Medium";

@include font-face($ims-font-bold, "roboto-v18-latin-500.28546717.woff2");
@include font-face(
  $ims-font-medium,
  "roboto-condensed-v16-latin-300.47d23646.woff2"
);
@include font-face($ims-font-news, "roboto-v18-latin-300.55536c8e.woff2");
