$button-line-height: 1;
$button-padding: 12px 20px;

// mixins

@mixin button-color($name, $color, $bg, $border) {
  #{$theme} .btn-#{$name} {
    color: $color;
    background-color: $bg;
    border: 1px solid $border;
  }
}

@mixin button-color-hover($name, $color, $bg, $border) {
  #{$theme} .btn-#{$name}:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: $color;
      background-color: $bg;
      border: 1px solid $border;
    }
  }
}

// button
#{$theme} .btn {
  font: 11px $ims-font-semibold;
  color: $ims-white-color;
  padding: $button-padding;
  text-decoration: none;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
  text-align: center;
  text-transform: uppercase;
  line-height: $button-line-height;

  &:not([disabled]) &:hover,
  &:not([disabled]) &:focus,
  &:not([disabled]) &:active {
    color: $ims-red-color;
  }

  &[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.65;
  }

  &-primary {
    padding-right: 20px;
  }

  &--icon {
    padding: 3px 7px 1px;
    width: 32px;

    span::before {
      font-size: $font-size-regular;
    }
  }
}

// link button
#{$theme} .btn-link {
  color: $ims-red-color;
  text-align: left;
  text-decoration: underline;
  padding: 8px;
  font: $font-size-regular $ims-font-regular;
  line-height: 1.5;
  text-transform: unset;
}

// button: colors

@include button-color(
  "primary",
  $ims-black-color,
  $ims-white-color,
  $ims-black-color
);
@include button-color(
  "secondary",
  $ims-white-color,
  $ims-light-grey-color,
  $ims-light-grey-color
);
@include button-color(
  "tertiary",
  $ims-black-color,
  $ims-white-color,
  $ims-black-color
);

// button: colors hover

@include button-color-hover(
  "primary",
  $ims-black-color,
  $ims-white-color,
  $ims-black-color
);
@include button-color-hover(
  "secondary",
  $ims-white-color,
  $ims-light-grey-color,
  $ims-light-grey-color
);
@include button-color-hover(
  "tertiary",
  $ims-black-color,
  $ims-white-color,
  $ims-black-color
);
