$font-folder-path: "../fonts/";

@mixin font-face($fontfamily, $filename, $fontweight: 400) {
  $temp-font-path: $font-folder-path + $filename;

  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: normal;
    font-stretch: normal;
    src: url($temp-font-path);
  }
}

//fonts
$ims-font-bold: "Roboto-700";
$ims-font-semibold: "Roboto-700";
$ims-font-news: "Roboto-400";
$ims-font-regular: "Roboto-400";
$ims-font-medium: "Roboto-300";
$ims-font-light: "Roboto-300";

@include font-face($ims-font-bold, "roboto-latin-700-normal.c18ee39.woff2");
@include font-face($ims-font-news, "roboto-latin-400-normal.176f8f5.woff2");
@include font-face($ims-font-medium, "roboto-latin-300-normal.37a7069.woff2");
