#{$theme} .pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
    font-size: $font-size-pagination;
    color: $ims-grey-color;

    & {
      padding: 0 3px;
    }

    & > button {
      color: $ims-red-color;
      //text-decoration: underline;
    }

    &.current {
      font-family: $ims-font-bold;
    }
  }

  &__previous {
    margin-right: 9px;
  }

  &__next {
    margin-left: 9px;
  }

  &--ellipsis {
    &::after {
      content: "\2026";
    }
  }

  &__section {
    nav {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 20px;
    }
  }
}

#{$theme} .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: $ims-red-color;
}
