#{$theme} .form-control {
  border: none;
  border-bottom: 1px solid $ims-black-color;
  background-color: $ims-white-color;
  border-radius: 0;
  box-shadow: none;
  font-size: $font-size-regular;
  color: $ims-grey-color;
  padding-top: 8px;
  font-weight: normal;
  padding-left: 0px;
  padding-right: 0px;

  &[disabled],
  &[readonly] {
    background-color: $ims-forms-background;
    opacity: 0.65;
  }

  &::placeholder {
    color: $ims-grey-color;
    opacity: 0.65;
    font-weight: normal !important;
  }
}

#{$theme} .has-error * {
  color: $ims-red-color !important;
  border-color: $ims-red-color !important;
}

#{$theme} .help-block {
  font-size: $font-size-small;
  display: block;
}

#{$theme} label {
  font-family: $ims-font-medium;
  color: $ims-black-color;
  font-size: $font-size-label;
  font-weight: normal;
  margin-bottom: 2px;
}

#{$theme} select[multiple].form-control {
  overflow-y: auto;
}

#{$theme} .form-group.select {
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0px;
    bottom: 4px;
    color: $ims-light-grey-color;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23b4b4b4' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
  }

  select {
    appearance: none;
    cursor: pointer;
    padding-right: 40px;
    line-height: 1;

    option {
      font-family: $ims-font-regular;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus,
    &:visited {
      background-color: $ims-white-color;
      border-color: $ims-black-color;
    }
  }
}

#{$theme} textarea {
  min-height: 60px;
}

#{$theme} input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

#{$theme} input[type="number"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
