$font-folder-path: "../fonts/";

@mixin font-face($fontfamily, $filename, $fontweight: 400) {
  $temp-font-path: $font-folder-path + $filename;

  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: normal;
    font-stretch: normal;
    src: url($temp-font-path);
  }
}

//fonts
$ims-font-bold: '"Helvetica Neue LT W0187HvCnObl", Helvetica, Arial, sans-serif';
@include font-face($ims-font-bold, "Helvetica-Neue-LT-W0187HvCnObl.woff2");

$ims-font-medium: '"Helvetica Neue LT W01_71488914",Helvetica,Arial,sans-serif';
@include font-face($ims-font-medium, "Helvetica-Neue-LT-W01_71488914.woff2");

$ims-font-news: '"Helvetica Neue LT W01_55 Roman", Helvetica, Arial, sans-serif;';
@include font-face($ims-font-news, "Helvetica-Neue-LT-W01_55-Roman.woff2");

$ims-font-book: '"Helvetica Neue LT W0187HvCnObl", Helvetica, Arial, sans-serif';
