@function headings($from: 1, $to: 6) {
  @if $from == $to {
    @return "#{$theme} h#{$from}";
  } @else {
    @return "#{$theme} h#{$from},"+headings($from + 1, $to);
  }
}

// headings font-size
#{headings(1)} {
  font-size: 40px;
}

#{headings(2, 3)} {
  font-size: 18px;
}

// headings font-family

#{headings(1, 2)} {
  font-family: $ims-font-bold;
  text-transform: uppercase;
}

#{headings(3, 6)} {
  font-family: $ims-font-news;
}

// headings color
#{headings(1, 6)} {
  color: $ims-dark-grey-color;

  &.line {
    position: relative;
    overflow: hidden;
    line-height: 24px;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: $ims-dark-grey-color;
      margin-left: 12px;
      position: absolute;
      top: 10px;
    }
  }
}

// headings reset
#{headings(1, 3)} {
  margin: 0;
}

// headings margin

#{headings(1, 3)} {
  margin: 0 0 20px;
}
