#{$theme} {
  font-family: $ims-font-regular;
  font-size: $font-size-regular;
  color: $ims-grey-color;
}

html,
body,
#{$theme} .full-height,
#{$theme},
#root,
header {
  height: 100%;
}

#{$theme} header .nav-link,
#{$theme} header .btn-link {
  color: $ims-white-color;
  text-decoration: none;
  padding-bottom: 4px;
  padding-left: 24px;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

#{$theme} header .btn-link {
  padding: 0;
}

#{$theme} header .nav-link:hover,
#{$theme} header .btn-link:hover {
  color: $ims-white-color;
  background-color: $ims-red-color;
}

#{$theme} header {
  background-color: $ims-blue-color;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
