$font-folder-path: "../fonts/";
//fonts
$ims-font-regular: "Montserrat-Regular";
$ims-font-semibold: "Montserrat-SemiBold";
$ims-font-bold: "Montserrat-Bold";
$ims-font-medium: "Montserrat-Medium";
$ims-font-light: "Montserrat-Light";

@mixin font-face($fontfamily, $filename, $fontweight: 400) {
  $temp-font-path: $font-folder-path + $filename;

  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: normal;
    font-stretch: normal;
    src: url($temp-font-path + ".ttf") format("truetype");
  }
}

@include font-face($ims-font-regular, "Montserrat-Regular");
@include font-face($ims-font-semibold, "Montserrat-SemiBold");
@include font-face($ims-font-bold, "Montserrat-Bold");
@include font-face($ims-font-medium, "Montserrat-Medium");
@include font-face($ims-font-light, "Montserrat-Light");
