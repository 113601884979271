#{$theme} .table {
  thead {
    tr > th {
      border-bottom: 3px solid #dbdbe3;
      font-family: $ims-font-bold;
      font-weight: normal;
      line-height: 1.3;
      padding-right: 18px;

      &::after {
        padding: 4px 4px 1px;
        opacity: 1;
        bottom: 10px;
        margin-left: -18px;
      }
    }
  }

  tbody {
    tr > td {
      border-top: 0;
    }

    tr.selected {
      background-color: $ims-turquoise-highlight-color !important;

      > td {
        font-family: $ims-font-bold;
        color: $ims-dark-grey-color;
        font-weight: normal;
      }

      .ims-btn--icon {
        display: inline-block;
        position: relative;
        top: 1px;
      }

      .ims-table-cell--nowrap {
        padding: 4px 8px;
      }
    }

    > tr {
      &:nth-of-type(odd) {
        background-color: $ims-forms-background;
      }
    }
  }
}

#{$theme} .dataTables_info {
  font-size: $font-size-table;
  padding-left: 8px;
  padding-bottom: 12px;
  text-align: left !important;
}
