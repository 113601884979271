$font-folder-path: "../fonts/";

@mixin font-face($fontfamily, $filename, $fontweight: 400) {
  $temp-font-path: $font-folder-path + $filename;

  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: normal;
    font-stretch: normal;
    src: url($temp-font-path);
  }
}

//fonts
$ims-font-bold: "SourceSansPro-Bold";
$ims-font-medium: "SourceSansPro";
$ims-font-news: "SourceSansPro";

@include font-face($ims-font-bold, "SourceSansPro-Bold.woff2");
@include font-face($ims-font-medium, "SourceSansPro.woff2");
@include font-face($ims-font-news, "SourceSansPro.woff2");
