#{$theme} {
  font-family: $ims-font-news;
  font-size: $font-size-regular;
  color: $ims-black-color;
  margin-bottom: 16px;
}

#{$theme} header {
  padding-top: 24px;
  background-color: $ims-white-color;
}

#{$theme} .navbar {
  align-items: unset;
  padding: 0;
}

#{$theme} .navbar-brand {
  padding: 0;
}

#{$theme} header .nav-link:hover,
#{$theme} header .btn-link:hover {
  opacity: 0.8;
}

#{$theme} .nav .nav-link,
#{$theme} .nav .btn-link {
  font-family: $ims-font-bold;
  font-weight: 700;
  font-size: 17px;
  padding-top: 16px;
  padding-bottom: 24px;
  padding-left: 0px;
  padding-right: 46px;
  text-transform: uppercase;
}

#{$theme} .nav .nav-link:hover,
#{$theme} .nav .btn-link:hover {
  color: $ims-black-color;
}

#{$theme} .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
