#{$theme} a {
  color: $ims-red-color;
  text-decoration: underline;

  &:hover {
    color: $ims-red-color;
  }

  &.grey {
    color: $ims-grey-color;
    text-decoration: underline;
  }
  &.navbar-brand {
    color: $ims-grey-color;
    text-decoration: none;
    padding: 0;
    font-family: $ims-font-bold;
    text-transform: uppercase;
    font-size: 24px;
  }
}

#{$theme} b,
#{$theme} strong {
  font-family: $ims-font-bold;
  font-weight: normal;
}

#{$theme} small {
  font-size: 12px;
  line-height: 18px;
}

#{$theme} .alert {
  position: relative;
  padding: 14px 0 12px 58px;
  border-radius: 5px;
  box-shadow: inset 6.123233995736765e-17px 1px 0 0 rgba(0, 0, 0, 0.2);
  color: $ims-white-color;
  font-family: $ims-font-bold;

  .alert-message {
    font-size: 14px;
  }

  &__icon {
    font-size: $font-size-x-large;
    position: absolute;
    left: 20px;
    top: 14px;
  }

  &-success {
    background-color: $ims-green-color;
  }

  &-danger {
    background-color: $ims-red-color;
  }

  &-info {
    background-color: $ims-turquoise-color;
  }
}
